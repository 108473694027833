import { Action, State } from "./types";

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setstate":
      return { ...state, ...action.payload };

    case "darkmode":
      return { ...state, darkmode: action.payload };

    case "decimals":
      return { ...state, nachkommastellen: action.payload };

    case "commata":
      return { ...state, commata: action.payload };

    default:
      return state;
  }
};

export const initialState: State = {
  darkmode: true,
  nachkommastellen: 2,
  commata: ",",
};
