import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import IonPadding from "../components/IonPadding";
import { toolList } from "./tools";
import { settings } from "ionicons/icons";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>DiaTools</IonTitle>
          <IonButtons collapse slot="end">
            <IonButton routerLink="/settings">
              <IonIcon icon={settings} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader translucent collapse="condense">
          <IonToolbar>
            <IonTitle size="large">DiaTools</IonTitle>
            <IonButtons collapse slot="end">
              <IonButton routerLink="/settings">
                <IonIcon icon={settings} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonPadding horizontal>
          <h1>Tools</h1>
        </IonPadding>
        {toolList.map((tool) => (
          <IonCard key={tool.route} button routerLink={tool.route}>
            {/* <img src="https://via.placeholder.com/500x100/20" /> */}
            <IonCardHeader>
              <IonCardTitle>{tool.name}</IonCardTitle>
              <IonCardSubtitle>{tool.subtitle}</IonCardSubtitle>
            </IonCardHeader>
          </IonCard>
        ))}
      </IonContent>
    </IonPage>
  );
};

export default Home;
