import { OneToolProperties } from "../../types/OneToolProperties";
import { BEtoKHProperties } from "./BEtoKH";
import { DiyEatingProperties } from "./DiyEating";
import { ForgottenEatinProperties } from "./ForgottenEatin";
import gUtoUKEProperties from "./g-u-to-u-ke";
import { KHtoBEProperties } from "./KHtoBE";

export const toolList: OneToolProperties[] = [
  ForgottenEatinProperties,
  DiyEatingProperties,
  KHtoBEProperties,
  BEtoKHProperties,
  gUtoUKEProperties,
];
