import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import {
  ifShouldBeRoundedThenJustDoIt,
  parsableNumberOrUndef,
} from "../../tools";
import { OneToolProperties } from "../../types/OneToolProperties";

import ReactKatex from "@matejmazur/react-katex";
import "katex/dist/katex.min.css";
import { useAppContext } from "../../context/useAppContext";

const DiyEatingPage: React.FC = () => {
  const { state } = useAppContext();
  const [KHsAll, setKHsAll] = React.useState<number | undefined>();
  const [weightSum, setWeightSum] = React.useState<number | undefined>();
  const [per, setPer] = React.useState<number | undefined>(100);

  const sum =
    KHsAll !== undefined && weightSum !== undefined && per !== undefined
      ? ifShouldBeRoundedThenJustDoIt((KHsAll / weightSum) * per, {
          decimals: state.nachkommastellen,
          commata: state.commata,
        })
      : undefined;

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{DiyEatingProperties.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{DiyEatingProperties.name}</IonCardTitle>
            <IonCardSubtitle>{DiyEatingProperties.subtitle}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <h2>Formel zum Berechnen:</h2>
            <p className="ion-text-center">
              <ReactKatex math="\text{Kohlenhydrate/100g}=\frac {Kohlenhydrate_{Gesamt}}{Gewicht_{Gesamt}}\times100"></ReactKatex>
            </p>
          </IonCardContent>
        </IonCard>

        <IonListHeader>Rechner:</IonListHeader>

        <IonItem>
          <IonLabel position="stacked">
            Summe aus Kohlehydraten in Gramm
          </IonLabel>
          <IonInput
            value={KHsAll}
            type="number"
            inputmode="decimal"
            onIonChange={(e) =>
              setKHsAll(parsableNumberOrUndef(e.detail.value))
            }
            placeholder="Summe aus Kohlehydraten"
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Komplettes Endgewicht</IonLabel>
          <IonInput
            value={weightSum}
            type="number"
            inputmode="decimal"
            onIonChange={(e) =>
              setWeightSum(parsableNumberOrUndef(e.detail.value))
            }
            placeholder="Komplettes Gewicht"
          />
        </IonItem>
        <IonListHeader>Ergebnis:</IonListHeader>
        <IonItem>
          {sum ? (
            <>
              {sum} g KH / {per} g
            </>
          ) : (
            "Fehlende Daten"
          )}
        </IonItem>
        <IonItem>
          <IonLabel>Pro</IonLabel>
          <IonInput
            value={per}
            type="number"
            inputmode="decimal"
            onIonChange={(e) => setPer(parsableNumberOrUndef(e.detail.value))}
            placeholder={""}
            inputMode="numeric"
          />
          <IonLabel>g</IonLabel>
        </IonItem>
        {/* <IonItem>
          <IonLabel>
            {sum ? (sum % 1 === 0 ? sum : sum?.toFixed(2)) : ""}
          </IonLabel>
        </IonItem> */}
      </IonContent>
    </IonPage>
  );
};

export const DiyEatingProperties: OneToolProperties = {
  name: "Selbst gebacken",
  subtitle:
    "Rechne aus, wie viele Kohlenhydrate pro 100g in deinem selbst gemachten Essen ist",
  route: "/diy-eating",
  component: DiyEatingPage,
};

export default DiyEatingPage;
