import React from "react";

const ifThenElseUndefined = (test?: boolean, twu?: any) =>
  test === true ? twu : undefined;

const IonPadding: React.FC<{
  top?: boolean;
  bottom?: boolean;
  right?: boolean;
  left?: boolean;
  horizontal?: boolean;
  vertical?: boolean;
  all?: boolean;
}> = (props) => (
  <div
    className={[
      ifThenElseUndefined(props.top, "ion-padding-top"),
      ifThenElseUndefined(props.bottom, "ion-padding-bottom"),
      ifThenElseUndefined(props.right, "ion-padding-right"),
      ifThenElseUndefined(props.left, "ion-padding-left"),
      ifThenElseUndefined(props.vertical, "ion-padding-vertical"),
      ifThenElseUndefined(props.horizontal, "ion-padding-horizontal"),
      ifThenElseUndefined(props.all, "ion-padding"),
    ].join(" ")}>
    {props.children}
  </div>
);

export default IonPadding;
