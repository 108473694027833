import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { OneToolProperties } from "../../types/OneToolProperties";
import ReactKatex from "@matejmazur/react-katex";
import {
  ifShouldBeRoundedThenJustDoIt,
  parsableNumberOrUndef,
} from "../../tools";
import { useAppContext } from "../../context/useAppContext";

const eatenOrForgottenProperties = { eaten: true, forgotten: false };

const ForgottenEatin: React.FC = () => {
  const { state } = useAppContext();
  const [eatenorforgotten, setEatenOrForgotten] = useState<boolean>(
    eatenOrForgottenProperties.forgotten
  );
  const [allkh, setAllKH] = useState<number | undefined>();
  const [allWeight, setAllWeight] = useState<number | undefined>();
  const [eatenorforgotval, seteatenorforgotval] = useState<
    number | undefined
  >();

  const result =
    allkh && allWeight && eatenorforgotval
      ? allkh *
        ((eatenorforgotten === eatenOrForgottenProperties.eaten
          ? eatenorforgotval
          : allWeight - eatenorforgotval) /
          allWeight)
      : undefined;

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle> {ForgottenEatinProperties.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{ForgottenEatinProperties.name}</IonCardTitle>
            <IonCardSubtitle>
              {ForgottenEatinProperties.subtitle}
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <p>
              <ReactKatex math="\text{KH}_{gegessen}=\frac{Gewicht_{gegessen}}{Gewicht_{gesamt}}\times \text{KH}_{gesamt}" />
            </p>
            <p>Mit</p>
            <p>
              <ReactKatex math="\text{Gewicht}_{gegessen}=\text{Gewicht}_{gesamt}-\text{Gewicht}_{vergessen}" />
            </p>
            <br />
            <p>
              <ReactKatex math="\text{KH}_{gegessen}" /> ist der Teil, den du
              gegessen hast
            </p>
          </IonCardContent>
        </IonCard>
        <IonListHeader>Rechner:</IonListHeader>
        <IonItem>
          <IonLabel position="stacked">KH/BE gesamt:</IonLabel>
          <IonInput
            type="number"
            inputmode="decimal"
            value={allkh}
            onIonChange={(e) => setAllKH(parsableNumberOrUndef(e.detail.value))}
            placeholder="Beispiel: 3"
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Gewicht gesamt:</IonLabel>
          <IonInput
            type="number"
            inputmode="decimal"
            value={allWeight}
            onIonChange={(e) =>
              setAllWeight(parsableNumberOrUndef(e.detail.value))
            }
            placeholder="Beispiel: 15"
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Gewicht{" "}
            {eatenorforgotten === eatenOrForgottenProperties.eaten
              ? "gegessen"
              : "vergessen"}
            :
          </IonLabel>
          <IonInput
            type="number"
            inputmode="decimal"
            value={eatenorforgotval}
            onIonChange={(e) =>
              seteatenorforgotval(parsableNumberOrUndef(e.detail.value))
            }
            placeholder="Beispiel: 15"
          />
        </IonItem>
        <IonItem>
          <IonLabel>
            {eatenorforgotten === eatenOrForgottenProperties.eaten
              ? "Gegessen"
              : "Vergessen"}
          </IonLabel>
          <IonToggle
            checked={eatenorforgotten}
            onIonChange={(e) => setEatenOrForgotten(e.detail.checked)}
          />
        </IonItem>
        <IonListHeader>Ergebnis</IonListHeader>
        <IonItem>
          KH/BE gegessen:{" "}
          {result
            ? ifShouldBeRoundedThenJustDoIt(result, {
                decimals: state.nachkommastellen,
                commata: state.commata,
              })
            : "Fehlende Werte"}
        </IonItem>
        <IonItem>
          KH/BE nicht gegessen:{" "}
          {result
            ? ifShouldBeRoundedThenJustDoIt((allkh ?? 0) - result, {
                decimals: state.nachkommastellen,
                commata: state.commata,
              })
            : "Fehlende Werte"}
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export const ForgottenEatinProperties: OneToolProperties = {
  name: "Essen Vergessen",
  subtitle:
    "Rechne aus wieviel du an Insulin abgeben musst, wenn du nur einen Teil gegessen hast",
  route: "/forgotten-eatin",
  component: ForgottenEatin,
};

export default ForgottenEatin;
