import { Storage } from "@capacitor/storage";
import { initialState } from "./otherStuff";
import { State } from "./types";

export const storeKey = "diacalcState";
export const serializeState = (state: State): string => JSON.stringify(state);
export const deserialiteState = (state: string): State => ({
  ...initialState,
  ...JSON.parse(state),
});

export const persistData = (state: State) => {
  return Storage.set({ key: storeKey, value: serializeState(state) });
};

export const loadDataOrInitialState = async () => {
  let data = await Storage.get({ key: storeKey });
  if (data.value !== null) return deserialiteState(data.value);
  else return initialState;
};
