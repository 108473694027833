import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { OneToolProperties } from "../../types/OneToolProperties";
import ReactKatex from "@matejmazur/react-katex";
import {
  ifShouldBeRoundedThenJustDoIt,
  parsableNumberOrUndef,
} from "../../tools";
import { useAppContext } from "../../context/useAppContext";
const BEtoKHPage = () => {
  const [bes, setBEs] = useState<number | undefined>();
  const { state } = useAppContext();
  const result =
    bes !== undefined
      ? ifShouldBeRoundedThenJustDoIt(bes * 12, {
          decimals: state.nachkommastellen,
          commata: state.commata,
        })
      : undefined;
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{BEtoKHProperties.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{BEtoKHProperties.name}</IonCardTitle>
            <IonCardSubtitle>{BEtoKHProperties.subtitle}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Formel zum selbst berechnen</p>
            <p>
              <ReactKatex math="\text{Kohlehydrate}=\text{Broteinheiten}\times{12}" />
            </p>
          </IonCardContent>
        </IonCard>
        <IonListHeader>Rechner:</IonListHeader>
        <IonItem>
          <IonLabel position="stacked">Broteinheiten</IonLabel>
          <IonInput
            type="number"
            inputmode="decimal"
            value={bes}
            onIonChange={(e) => setBEs(parsableNumberOrUndef(e.detail.value))}
            placeholder="Beispiel: 1"
          />
        </IonItem>
        <IonListHeader>Ergebnis:</IonListHeader>
        <IonItem>{result ? result + " g KH" : "Fehlende Daten"}</IonItem>
      </IonContent>
    </IonPage>
  );
};

export const BEtoKHProperties: OneToolProperties = {
  name: "BE zu KH",
  subtitle: "Konvertiere BE's zu KH's",
  route: "/be-to-kh",
  component: BEtoKHPage,
};

export default BEtoKHPage;
