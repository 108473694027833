import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { OneToolProperties } from "../../types/OneToolProperties";
import ReactKatex from "@matejmazur/react-katex";
import {
  ifShouldBeRoundedThenJustDoIt,
  parsableNumberOrUndef,
} from "../../tools";
import { useAppContext } from "../../context/useAppContext";
const KHtoBEPage = () => {
  const { state } = useAppContext();
  const [khs, setKHs] = useState<number | undefined>();

  const result =
    khs !== undefined
      ? ifShouldBeRoundedThenJustDoIt(khs / 12, {
          decimals: state.nachkommastellen,
          commata: state.commata,
        })
      : undefined;
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{KHtoBEProperties.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{KHtoBEProperties.name}</IonCardTitle>
            <IonCardSubtitle>{KHtoBEProperties.subtitle}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <p>Formel zum selbst berechnen</p>
            <p>
              <ReactKatex math="\text{Broteinheiten}=\frac {Kohlehydrate}{12}" />
            </p>
          </IonCardContent>
        </IonCard>
        <IonListHeader>Rechner:</IonListHeader>
        <IonItem>
          <IonLabel position="stacked">Gramm Kohlehydrate</IonLabel>
          <IonInput
            type="number"
            inputmode="decimal"
            value={khs}
            onIonChange={(e) => setKHs(parsableNumberOrUndef(e.detail.value))}
            placeholder="Beispiel: 12"
          />
        </IonItem>
        <IonListHeader>Ergebnis:</IonListHeader>
        <IonItem>{result ? result + " BE" : "Fehlende Daten"}</IonItem>
      </IonContent>
    </IonPage>
  );
};

export const KHtoBEProperties: OneToolProperties = {
  name: "KH zu BE",
  subtitle: "Konvertiere KH's zu BE's",
  route: "/kh-to-be",
  component: KHtoBEPage,
};

export default KHtoBEPage;
