import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import { logoGithub } from "ionicons/icons";
import {
  ActionSetCommata,
  ActionSetDarkmode,
  ActionSetNachkommastellen,
} from "../context/actions";
import { parsableNumberOrUndef } from "../tools";
import { initialState } from "../context/otherStuff";
import { useAppContext } from "../context/useAppContext";

const SettingsPage: React.FC = () => {
  const { state, dispatch } = useAppContext();
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Einstellungen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <IonListHeader>Wichtige Einstellungen</IonListHeader> */}
        <IonItem>
          <IonToggle
            checked={state.darkmode}
            onIonChange={(e) => dispatch(ActionSetDarkmode(e.detail.checked))}
            slot="end"
          />
          <IonLabel>Darkmode</IonLabel>
        </IonItem>
        <IonListHeader>Nachkommastellen</IonListHeader>
        <IonItem>
          <IonRange
            value={state.nachkommastellen}
            onIonChange={(e) =>
              dispatch(
                ActionSetNachkommastellen(
                  parsableNumberOrUndef(e.detail.value) ??
                    initialState.nachkommastellen
                )
              )
            }
            min={0}
            max={12}
            snaps>
            <IonLabel slot="start">0</IonLabel>
            <IonLabel slot="end">12</IonLabel>
          </IonRange>
        </IonItem>

        <IonItem>
          <IonLabel>Kommata</IonLabel>
          <IonSelect
            value={state.commata}
            onIonChange={(e) => dispatch(ActionSetCommata(e.detail.value))}>
            <IonSelectOption value=",">,</IonSelectOption>
            <IonSelectOption value=".">.</IonSelectOption>
          </IonSelect>
        </IonItem>

        <IonCard>
          <IonCardHeader>
            <IonCardTitle>DiaTools</IonCardTitle>
            <IonCardSubtitle>
              By <a href="https://github.com/markxoe">markxoe</a>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonCard button href="https://github.com/markxoe/diatools">
              <IonCardHeader>
                <IonCardSubtitle>Source Code auf</IonCardSubtitle>
                <IonCardTitle>
                  <IonIcon icon={logoGithub} /> GitHub
                </IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
