export const parsableNumberOrUndef = (
  input: any,
  parseAsFloat: boolean = true
): number | undefined => {
  switch (typeof input) {
    case "bigint":
    case "number": {
      return input as number;
    }

    case "string": {
      if (input !== "") {
        return ifNanThenUndefined(
          parseAsFloat ? parseFloat(input) : parseInt(input)
        );
      }
    }
  }
};
const ifNanThenUndefined = (i: number) => (isNaN(i) ? undefined : i);

export const ifShouldBeRoundedThenJustDoIt = (
  input: number,
  options: {
    decimals?: number;
    removeZeros?: boolean;
    commata?: string;
  }
): string =>
  replaceAllCommata(
    input % 1 === 0
      ? input.toString()
      : (options.removeZeros ?? true) && (options.decimals ?? 2) !== 0
      ? removeZerosAtTheEnd(input?.toFixed(options.decimals ?? 2))
      : input?.toFixed(options.decimals ?? 2),
    options.commata ?? "."
  );

const reverseString = (input: string) => {
  return input.split("").reverse().join("");
};
export const removeZerosAtTheEnd = (input: string): string => {
  if (input.length) {
    const reversedInput = reverseString(input);
    let i = reversedInput.match(/([0]*)([0-9]*[,.][0-9]*)/);
    return i ? removePointAtTheEnd(reverseString(i[2])) : input;
  }
  return input;
};
export const removePointAtTheEnd = (input: string): string => {
  const match = input.match(/([0-9]+)(\.)$/);
  return match !== null ? match[1] : input;
};

export const replaceAllCommata = (input: string, replaceWith: string) =>
  input.replace(/([,.])/gm, replaceWith);
