import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { OneToolProperties } from "../../types/OneToolProperties";
import ReactKatex from "@matejmazur/react-katex";
import {
  ifShouldBeRoundedThenJustDoIt,
  parsableNumberOrUndef,
} from "../../tools";
import { useAppContext } from "../../context/useAppContext";
const GUtoUKEPage: React.FC = () => {
  const { state } = useAppContext();
  const [inputValue, setInputValue] = useState<number | undefined>();
  const result = inputValue
    ? ifShouldBeRoundedThenJustDoIt(10 / inputValue, {
        commata: state.commata,
        decimals: state.nachkommastellen,
      })
    : undefined;

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{gUtoUKEProperties.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{gUtoUKEProperties.name}</IonCardTitle>
            <IonCardSubtitle>{gUtoUKEProperties.subtitle}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <p>
              <ReactKatex math="\text{U/KE}=\frac{10}{\text{g/U}}" />
            </p>
            <p>Und</p>
            <p>
              <ReactKatex math="\text{g/U}=\frac{10}{\text{U/KE}}" />
            </p>
          </IonCardContent>
        </IonCard>
        <IonListHeader>Rechner:</IonListHeader>
        <IonItem>
          <IonLabel position="stacked">g/U oder U/KE</IonLabel>
          <IonInput
            placeholder="Beispiel: 3"
            value={inputValue}
            onIonChange={(e) =>
              setInputValue(parsableNumberOrUndef(e.detail.value))
            }
            type="number"
            inputmode="decimal"
          />
        </IonItem>
        <IonListHeader>Ergebnis:</IonListHeader>
        <IonItem>
          {result ? result + " U/KE oder g/U" : "Fehlende Angaben"}
        </IonItem>
      </IonContent>
    </IonPage>
  );
};

export const gUtoUKEProperties: OneToolProperties = {
  name: "g/U zu U/KE",
  subtitle: "Konvertiere g/U zu U/KE. Beides Einheiten von KH-Faktoren",
  component: GUtoUKEPage,
  route: "/gu-to-uke",
};

export default gUtoUKEProperties;
