import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./css/custom.css";
import Home from "./pages/Home";
import { toolList } from "./pages/tools";
import { createContext, useEffect, useReducer } from "react";
import SettingsPage from "./pages/Settings";
import { StateContext } from "./context/types";
import { initialState, reducer } from "./context/otherStuff";
import { loadDataOrInitialState, persistData } from "./context/persistance";
import { ActionSetState } from "./context/actions";

export const AppContext = createContext<StateContext>({} as StateContext);

const App: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //#region Data persistance
  useEffect(() => {
    loadDataOrInitialState().then((newState) => {
      console.log(newState);
      dispatch(ActionSetState(newState));
    });
  }, []);

  useEffect(() => {
    persistData(state).catch((e) => console.error(e));
  }, [state]);
  //#endregion

  //#region Darkmode
  useEffect(() => {
    document.body.classList.add(state.darkmode ? "dark" : "light");
    document.body.classList.remove(!state.darkmode ? "dark" : "light");
  }, [state.darkmode]);
  //#endregion

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" exact>
              <Home />
            </Route>
            <Route path="/settings" exact>
              <SettingsPage />
            </Route>
            {toolList.map((tool) => (
              <Route key={tool.route} path={tool.route} exact>
                <tool.component />
              </Route>
            ))}
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AppContext.Provider>
  );
};

export default App;
