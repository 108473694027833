import { Action, State } from "./types";

export const ActionSetState = (newState: State): Action => ({
  type: "setstate",
  payload: newState,
});

export const ActionSetDarkmode = (newState: boolean): Action => ({
  type: "darkmode",
  payload: newState,
});

export const ActionSetNachkommastellen = (
  nachkommastellen: number
): Action => ({ type: "decimals", payload: nachkommastellen });

export const ActionSetCommata = (commata: string): Action => ({
  type: "commata",
  payload: commata,
});
